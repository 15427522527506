import { Company } from '~/types/auth/TokenPayload'

export const getWorkspace = (id: number | null, workspaces: Company[]) => {
  if (!workspaces?.length || !id) {
    return {} as Company
  }

  const defaultWorkspace = workspaces[0]
  const workspaceInfo = workspaces.find((workspace) => workspace.id === id) ?? defaultWorkspace

  return workspaceInfo
}
