import { useRouter, usePathname } from 'next/navigation'
import { useSignedAWSUrl } from '~/hooks/file-upload/use-signed-aws-url'
import { useUserContext } from '~/src/providers/user-provider'
import { getFullName } from '~/utils/get-full-name'
import { AccountDropdownMenu } from '../../../account-dropdown-menu'
import { LoginMenuButton } from '../../../login-menu-button'
import { SignupMenuButton } from '../../../signup-menu-button'
import { WorkspacesDropdownMenu } from '../../../workspaces-dropdown-menu'

export const NavbarButtons = () => {
  const router = useRouter()
  const pathname = usePathname()

  const { user, isSignedIn, primaryEmail } = useUserContext()
  const avatarURL = useSignedAWSUrl(user?.profileImage)

  const getCallbackUrl = () => {
    if (!pathname) {
      return ''
    }

    return encodeURIComponent(pathname)
  }

  if (!isSignedIn) {
    return (
      <div className='flex gap-2'>
        <LoginMenuButton onClick={() => router.push(`/login?callbackUrl=${getCallbackUrl()}`)} />
        <SignupMenuButton onClick={() => router.push(`/signup?callbackUrl=${getCallbackUrl()}`)} />
      </div>
    )
  }

  return (
    <>
      <div className='hidden h-6 border-r border-neutral-00 lg:block' />
      {isSignedIn && (
        <AccountDropdownMenu
          email={primaryEmail?.emailAddress ?? ''}
          imageSrc={avatarURL ?? ''}
          name={getFullName(user?.firstName, user?.lastName)}
        />
      )}

      <div className='hidden h-6 border-r border-neutral-00 sm:block' />
      <WorkspacesDropdownMenu />
    </>
  )
}
