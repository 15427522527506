export const getSignedUrl = async ({ key, name, type }: { key: string; name?: string; type?: string }) => {
  try {
    let url = `/api/generateTemporaryUrl?key=${encodeURIComponent(key)}`
    if (type) url += `&type=${encodeURIComponent(type)}`
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return { ...data, name }
  } catch (error) {
    if (error instanceof Error) {
      throw error.message
    } else {
      throw 'An unexpected error occurred'
    }
  }
}
