import React from 'react'
import Link from 'next/link'
import { useSubdomainRouter } from '~/hooks/use-subdomain-router'
import { Routes } from '~/src/app/_constants/routes'

export const WorkspacesDropdownMenuFooter: React.FC = () => {
  const { push } = useSubdomainRouter()

  const handleSwitchToJobSeeker = async () => {
    push(Routes.user.profile, '')
  }

  return (
    <Link
      href='#'
      onClick={handleSwitchToJobSeeker}
      className='flex w-full items-center justify-between gap-1 p-4 text-brand-primary-rest hover:cursor-pointer'
    >
      <div className='flex items-center gap-2 text-sm font-medium'>Switch to Job Seeker</div>
      <i className='fa-regular fa-arrow-right-from-bracket' />
    </Link>
  )
}
