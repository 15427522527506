import React, { PropsWithChildren } from 'react'
import { useSubdomainRouter } from '~/hooks/use-subdomain-router'
import { Divider } from '~/scalis-components/core/divider'
import { Routes } from '~/src/app/_constants/routes'
import { useUserContext } from '~/src/providers/user-provider'
import { getWorkspace } from '../workspace-button'
import { WorkspacesDropdownSubmenuItem } from './workspaces-dropdown-submenu-item'

export const WorkspacesDropdownSubmenuContent: React.FC<PropsWithChildren> = () => {
  const { companies, currentWorkspace } = useUserContext()

  const { push } = useSubdomainRouter()

  if (!companies || !currentWorkspace) return null

  return (
    <>
      <div className='flex flex-col gap-2 p-2'>
        {companies.map((item) => {
          const isCurrentWorkspace = item.id === currentWorkspace.currentCompany?.id
          const { imageSrc, name, subdomain } = getWorkspace(item.id, companies) ?? companies[0]

          return (
            <WorkspacesDropdownSubmenuItem
              key={item.id}
              id={item.id}
              name={name}
              subdomain={subdomain}
              imageSrc={imageSrc}
              active={isCurrentWorkspace}
              onClick={async () => {
                push(Routes.workspace.dashboard, item.subdomain)
              }}
            />
          )
        })}
      </div>
      <Divider />
    </>
  )
}
