'use client'

import React, { useState } from 'react'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import useNavbarType, { NavbarType } from '~/hooks/use-navbar-type'
import useBreakpoint from '~/hooks/useBreakpoint'
import { InputSearch } from '~/scalis-components/core/input/search/input-search'
import ScalisLogo from '~/scalis-components/core/navbar/logo/scalis-logo'
import { NavbarButtons } from '~/scalis-components/core/navbar/navbar-template/components/navbar-buttons/navbar-buttons'
import { NavbarTemplateLogoOnly } from '~/scalis-components/core/navbar/navbar-template/components/navbar-template-logo-only/navbar-template-logo-only'
import { shouldRenderSearchMenu } from '~/scalis-components/core/navbar/navbar-template/navbar-template.utils'
import { MobileNav } from '~/scalis-components/landing/components/navbar/mobile-nav'
import { useNavLinks } from '~/scalis-components/team/navbar-variations/default/navbar-default.hooks'
import { useUserContext } from '~/src/providers/user-provider'
import { cn } from '~/utils/cn'

export interface NavBarTemplateProps {
  isLogoOnly?: boolean
  showCompanyName?: boolean
}

const NavbarTemplate = ({ isLogoOnly, showCompanyName }: NavBarTemplateProps) => {
  const navLinks = useNavLinks()
  const { lg, md } = useBreakpoint()
  const pathname = usePathname()
  const navbarType = useNavbarType()
  const router = useRouter()

  const [search, setSearch] = useState('')

  const { isSignedIn, currentWorkspace, subdomain } = useUserContext()

  const companyInfo = currentWorkspace?.currentCompany

  if (isLogoOnly) {
    return (
      <NavbarTemplateLogoOnly
        isLoggedOut={!isSignedIn}
        endText={showCompanyName ? companyInfo?.name : undefined}
        subdomain={companyInfo?.subdomain}
        logo={companyInfo?.imageSrc ?? undefined}
        companyName={companyInfo?.name ?? undefined}
      />
    )
  }

  const breakpoint = subdomain ? md : true

  const showSearchMenu = shouldRenderSearchMenu(isSignedIn, pathname, breakpoint)

  const handleOnSubmit = (data: any) => {
    router.push(`/jobs?search=${encodeURIComponent(data.search)}`)
  }

  return (
    <div
      data-testid='header'
      className={cn(
        'flex w-full items-center justify-between border-b border-neutral-10 px-4 py-[0.375rem]',
        navbarType === NavbarType.COMPANY_WORKSPACE ? 'bg-brand-primary-light-00' : '',
      )}
    >
      <div className='flex w-full items-center gap-4 lg:w-auto'>
        <MobileNav />
        <ScalisLogo
          subdomain={subdomain ?? ''}
          logo={companyInfo?.imageSrc ?? undefined}
          companyName={companyInfo?.name ?? undefined}
        />

        {showSearchMenu && (
          <div className='w-full lg:w-[15.625rem]'>
            <InputSearch
              name='jobSearchTitle'
              placeholder='Job Title'
              search={search}
              medium={lg}
              onChangeSearch={(value) => {
                setSearch(value)
              }}
              onPressEnter={() => {
                handleOnSubmit({ search })
              }}
            />
            {/* <SearchMenu onSubmit={handleOnSubmit} /> */}
          </div>
        )}
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex gap-2'>
          {lg &&
            navLinks.map(({ label, href }, index) => (
              <Link
                role='link'
                aria-label={label}
                className={cn(
                  'whitespace-nowrap rounded-lg p-2 text-sm/6 font-medium text-neutral-primary hover:bg-brand-primary-light-10 active:bg-button-brand-primary-light-hover',
                )}
                href={href}
                key={`navbar-link-index-${index}`}
              >
                {label}
              </Link>
            ))}
        </div>

        <NavbarButtons />
      </div>
    </div>
  )
}

export default NavbarTemplate
