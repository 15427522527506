import { Title, TitleSize } from '~/scalis-components/core/typography'
import { handleSignOut } from '~/utils/auth/handle-sign-out'
import { cn } from '~/utils/cn'
import ScalisLogo from '../../../logo/scalis-logo'
import { LogoutMenuButton } from '../../../logout-menu-button'

type Props = {
  isLoggedOut: boolean
  isLoading: boolean
  endText?: string | null
  subdomain?: string
  logo?: string
  companyName?: string
}

export const NavbarTemplateLogoOnly = ({
  isLoggedOut,
  isLoading,
  subdomain,
  logo,
  companyName,
  endText,
}: Partial<Props>) => {
  return (
    <div
      className={cn(
        'flex h-[3.313rem] w-full items-center justify-between border-b border-neutral-10 bg-neutral-00 px-4 py-[0.375rem]',
      )}
    >
      <div className='flex w-full items-center gap-6 lg:w-auto'>
        <div className='w-24 overflow-hidden'>
          <ScalisLogo
            subdomain={subdomain}
            logo={logo}
            companyName={companyName}
          />
        </div>
      </div>
      <div>{!isLoggedOut && !isLoading && <LogoutMenuButton onClick={handleSignOut} />}</div>
      {endText && <Title size={TitleSize.small}>{endText}</Title>}
    </div>
  )
}
